import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';

//tags
import ReactGA from "react-ga4";


//images
import Logo from './images/logo.png';
import Insta from './images/instagram.png';
import Programerr from './images/clogo2.png'

function App() {
  return (
    <div>
    <div className="App">

    <div className='total'>
    <img src={Logo}  className='logo'/>
    

    
    </div>
    <div className='social'>
    <p className='title'>New Fashion Store is coming to give great experience.</p>
    <a href='https://www.instagram.com/theviolence.store/' target='_blank'  ><img src={Insta} className='insta' /></a>
    
    
    </div>
    <div className='supportTeam'>
    <p className='support'>IT Support by</p>
    <a href='https://www.programerr.com/' target='_blank'><img src={Programerr} className='Programerr'/></a>
    
    </div>
    
    </div>
    </div>
  );
}

export default App;
